import React from 'react';
import DefaultLayout from '../layouts';

const PageNotFound = () => (
  <DefaultLayout>
    <h1>404</h1>
  </DefaultLayout>
);

export default PageNotFound;
